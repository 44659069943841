import { motion } from "framer-motion";

export const Logo = () => {
  const letters = "lister".split("");
  const sentence = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.08,
        delayChildren: 0.2,
      }
    }
  }

  const letter = {
    hidden: {
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
    },
    visible: {
      y: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
    }
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <motion.div
      variants={sentence}
      initial="hidden"
      animate="visible"
      className="block"
      style={{ overflow: "hidden" }}
      >
        {
          letters.map((l, i) => {
            console.log(i)
              return <motion.div
              key={"ll"+i}
              className="logo-text"
              variants={letter}
            >{l}</motion.div>
          })
        }
      </motion.div>
    </div>
  )
}

export const Logo2 = () => {
  const letters = ".industries".split("");
  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.08,
      }
    }
  }

  const letter = {
    hidden: {
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
    },
    visible: {
      y: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
    }
  }

  return (
    <motion.div
    variants={sentence}
    initial="hidden"
    animate="visible"
    style={{ overflow: "hidden" }}
    >
      {
        letters.map((l, i) => {
          console.log(i)
            return <motion.div
            key={"ll"+i}
            style={{ display: 'inline-block' }}
            variants={letter}
          >{l}</motion.div>
        })
      }
    </motion.div>
  )
}

export const Button = ({ text, url }) => {
  const btn = {
    hover: {
      backgroundColor: ["#000000", "#000000", "#ffffff"],
      transition: { duration: 0.25, times: [0, 0.5, 1] },
    }
  }

  const label = {
    hover: {
      color: ["#fff", "#fff", "#000"],
      y: [0, 100, 0],
      transition: { duration: 0.25, times: [0, 0.5, 1], staggerChildren: 0.08 },
    },
  }

  const wrapper = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.08,
        delayChildren: 0.2,
      }
    }
  }

  const letter = {
    hidden: {
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
    },
    visible: {
      y: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
    }
  }

  return (
    <div className="btn-wrapper" style={{ overflow: 'hidden' }}>
      <motion.div
      className='btn'
      variants={btn}
      whileHover="hover"
      style={{ overflow: 'hidden' }}
      onClick={() => { window.location = url}}
      >
        <motion.div
        variants={label}
        style={{ overflow: 'hidden' }}
        >
          <motion.div
            variants={wrapper}
            initial="hidden"
            animate="visible"
          >
          {
            text.split("").map((l, i) => {
              console.log(i)
                return <motion.div
                key={text+"-"+i}
                style={{ display: 'inline-block' }}
                variants={letter}
              >{l}</motion.div>
            })
          }
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  )
}

import './App.css';
import { Logo, Logo2 } from './util';
import boar from './boar.png';
import fox from './fox.png'
import deer from './deer.png'
import bg from './bg.jpg'
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const variants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    }
  };

function Services() {

    const navigate = useNavigate();
    const handleHome = () => navigate('/');

  return (
    <div className="App">
      <div className='logo-wrapper'>
        <div className="logo" onClick={handleHome}>
          <Logo /><Logo2 />
        </div>
      </div>
      <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      className="page-content" style={{
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,1) 50%,rgba(0,0,0,0.5) 60%,rgba(0,0,0,0) 70%), url(${bg})`,
        backgroundPositionX: 'right',
        backgroundSize: 'contain',
        backgroundRepeatX: 'no-repeat',
        backgroundRepeatY: 'no-repeat' }}>
        <h3>Pest Control</h3>
        <div class="animals"> <img src={boar} /> <img src={fox} /> <img src={deer} /></div>
        <p>
            Lister Industries is aiming towards providing pest control services in 2024.
        </p>
        <h4 style={{marginBottom: "0.5rem"}}>Qualifications/Courses</h4>
        <i style={{opacity: 0.5}}>Attaining or Attained</i>
        <ul>
            <li>Work Safely Around Aircraft (PUAFIR017)</li>
            <li>Crew Resource Management</li>
            <li>Low Flying in a Wires and Hazards Environment</li>
        </ul>
      </motion.div>
      <motion.div variants={variants} initial="hidden" animate="visible" className='small-wrapper'>
        <small><a target="_blank" href="https://icons8.com/icon/WF3QS68v5EFm/boar">Boar</a>, <a target="_blank" href="https://icons8.com/icon/OzPzLNFKW3qG/fox">Fox</a>, <a target="_blank" href="https://icons8.com/icon/PI2iDb1j6Nxf/deer">Deer</a> icons by <a target="_blank" href="https://icons8.com">Icons8</a></small>
      </motion.div>
    </div>
  );
}

export default Services;

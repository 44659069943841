import './App.css';
import { Logo, Logo2 } from './util';
import bg from './bg2.jpg'
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const variants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    }
};

function Mission() {
    const navigate = useNavigate();
    const handleHome = () => navigate('/');

  return (
    <div className="App">
      <div className='logo-wrapper'>
        <div className="logo" onClick={handleHome}>
          <Logo /><Logo2 />
        </div>
      </div>
      <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      className="page-content mission-page" 
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,1) 50%,rgba(0,0,0,0.5) 60%,rgba(0,0,0,0) 70%), url(${bg})`,
        backgroundPositionX: 'right',
        backgroundPositionY: 'bottom',
        backgroundSize: 'contain',
        backgroundRepeatX: 'no-repeat',
        backgroundRepeatY: 'no-repeat' }}>
        <h3>Our Mission</h3>
        <p>
            Our mission is to pioneer innovative solutions for the challenges faced by individuals and government entities alike. We are dedicated to providing services that stand out as unique, catering to needs that few can address. <br/> <br/> <i>Whoever you are,</i> <i>whatever your needs,</i> <b>we will make it happen.</b><br /> <br/> We aim to constantly push boundaries to create novel and effective approaches that make a meaningful impact on society.
            <br /><br />
            <i>In a world that's constantly changing, one thing remains constant:</i> <br /><b>Lister Industries</b> - your ticket to <b>tomorrow</b>.
        </p>
      </motion.div>
      <motion.div variants={variants} initial="hidden" animate="visible" className='small-wrapper'>
        <small className='hide-mobile'>Photo by <a href="https://unsplash.com/@umut_sdf?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Umut Sedef</a> on <a href="https://unsplash.com/photos/sydney-opera-house-australia-during-nighttime-JGtotgvFcuA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></small>
      </motion.div>
    </div>
  );
}

export default Mission;

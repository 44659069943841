import './App.css';
import { Button, Logo, Logo2 } from './util';

function Home() {
  return (
    <div className="App">
      <div className='logo-wrapper'>
        <div className="logo">
          <Logo /><Logo2 />
        </div>
      </div>
      <div className='buttons'>
        <Button text="MISSION" url="/mission"></Button>
        <Button text="SERVICES" url="/services"></Button>
      </div>
    </div>
  );
}

export default Home;
